#results-chart-title {
    border-bottom: 3px solid var(--color-white-dark);
    color: var(--color-dark-base);
    cursor: help;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #results-chart-title {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0.775em;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 2.5vw;
        height: 8.5vh;
        padding-bottom: 1.5vh;
        text-align: center;
    }
}

@media only screen and (min-width: 600px) {
    #results-chart-title {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1em;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 10vw;
        height: 8.5vh;
        padding-bottom: 1.5vh;
        text-align: center;
    }
}

@media only screen and (min-width: 768px) {
    #results-chart-title {
        font-size: 1.2em;
        margin: 0 12.5vw;
    }
}

@media only screen and (min-width: 992px) {
    #results-chart-title {
        font-size: 1.6em;
    }
}

@media only screen and (min-width: 1200px) {
    #results-chart-title {
        font-size: 1.25em;
        margin: 0 10vw;
        padding: 1.5vh;
    }
}
