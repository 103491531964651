#calculator-intro-view {
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2.5vh 0;
    height: 100vh;
    width: 100vw;
}

#calculator-intro-content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#calculator-intro-buttons {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    #calculator-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #calculator-intro-image {
        display: none;
    }

    #calculator-intro-content {
        width: 85vw;
    }

    #calculator-intro-content h2 {
        margin-bottom: 3.5vh;
    }

    #calculator-intro-details {
        margin-top: 10vh;
    }

    #calculator-intro-details p {
        font-weight: 700;
    }

    #calculator-intro-buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 3.125vh;
        width: 85vw;
    }

    #calculator-intro-buttons button {
        width: 100%;
    }

    #calculator-intro-buttons button:first-child {
        margin-bottom: 2.75vh;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #calculator-intro-image {
        display: none;
    }

    #calculator-intro-content {
        width: 80vw;
    }

    #calculator-intro-content h2 {
        margin-bottom: 3.5vh;
    }

    #calculator-intro-details {
        margin: 12.5vh auto 0 auto;
        text-align: center;
        width: 75vw;
    }

    #calculator-intro-details p {
        font-weight: 700;
    }

    #calculator-intro-buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 2.5vh auto 0 auto;
        width: 75vw;
    }

    #calculator-intro-buttons button {
        width: 100%;
    }

    #calculator-intro-buttons button:first-child {
        margin-bottom: 2.25vh;
    }
}

@media only screen and (min-width: 768px) {
    #calculator-intro-content {
        width: 65vw;
    }

    #calculator-intro-content h2 {
        margin-bottom: 3.5vh;
    }

    #calculator-intro-details {
        margin-top: 25vh;
        width: 60vw;
    }

    #calculator-intro-buttons {
        width: 60vw;
    }
}

@media only screen and (min-width: 992px) {
    #calculator-intro-content {
        width: 65vw;
    }

    #calculator-intro-details {
        width: 60vw;
    }

    #calculator-intro-buttons {
        width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 2.5vw;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #calculator-intro-image {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 95vh;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-left: 2.25vw;
        width: 35vw;
    }

    #calculator-intro-image img {
        height: auto;
        width: 35vw;
    }

    #calculator-intro-content {
        margin-top: 5vh;
        width: 55vw;
    }

    #calculator-intro-content h2 {
        margin-bottom: 2.75vh;
        min-width: 15em;
        width: 30vw;
    }

    #calculator-intro-content p {
        min-width: 25em;
        width: 25vw;
    }

    #calculator-intro-details {
        border-top: 2px dashed var(--color-white-dark);
        margin: 30vh 0 2.75vh 0;
        min-width: 25em;
        padding-top: 4.5vh;
        text-align: left;
        width: 27.5vw;
    }

    #calculator-intro-buttons {
        -ms-flex-item-align: start;
        align-self: flex-start;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 1.5vw;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin: 0 0 7.75em 0;
        width: 45vw;
    }

    #calculator-intro-buttons button {
        min-width: 18em;
        width: 40%;
    }

    #calculator-intro-buttons button:first-child {
        margin-bottom: 0;
    }
}
