.link-gallery {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.link-gallery a {
    text-decoration: none;
}

.link-gallery-item {
    border: 1px solid var(--color-blue-dark);
    -webkit-box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.075);
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.075);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.link-gallery-item:hover {
    -webkit-box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
}

.link-gallery-item-text {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-light);
    color: var(--color-dark-base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-family: 'Readex Pro', 'sans-serif';
    font-weight: 300;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .link-gallery {
        width: 85vw;
    }

    a:last-child .link-gallery-item {
        margin-bottom: 0;
    }

    .link-gallery-item {
        margin-bottom: 3.75vh;
        width: 85vw;
    }

    .link-gallery-item-image {
        height: auto;
        margin-bottom: -1vh;
        width: 100%;
    }

    .link-gallery-item-text {
        font-size: 1.15em;
        padding: 4.5vh 3.5vw;
    }
}

@media only screen and (min-width: 600px) {
    .link-gallery {
        width: 80vw;
    }

    a:last-child .link-gallery-item {
        margin-bottom: 0;
    }

    .link-gallery-item {
        margin-bottom: 3.75vh;
        width: 85vw;
    }

    .link-gallery-item-image {
        height: auto;
        margin-bottom: -1vh;
        width: 100%;
    }

    .link-gallery-item-text {
        font-size: 1.5em;
        padding: 4.5vh 3.5vw;
    }
}

@media only screen and (min-width: 768px) {
    .link-gallery {
        width: 65vw;
    }

    .link-gallery-item {
        width: 65vw;
    }

    .link-gallery-item-image {
        height: auto;
        width: 100%;
    }

    .link-gallery-item-text {
        font-size: 1.75em;
    }
}

@media only screen and (min-width: 992px) {
    .link-gallery {
        width: 60vw;
    }

    .link-gallery-item {
        width: 60vw;
    }

    .link-gallery-item-image {
        height: auto;
        width: 100%;
    }

    .link-gallery-item-text {
        font-size: 2em;
    }
}

@media only screen and (min-width: 1200px) {
    .link-gallery {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 75vw;
    }

    .link-gallery-item {
        margin-bottom: 0;
        width: calc((75vw / 3) - 5vw);
    }

    .link-gallery-item-image {
        height: auto;
        width: 100%;
    }

    .link-gallery-item-text {
        font-size: 1.125em;
        padding: 5vh 3.5vw;
    }
}
