.color-dark {
    color: var(--color-dark-base);
}

.color-lime {
    color: var(--color-lime-base);
}

.color-pink {
    color: var(--color-pink-base);
}

.color-white-light {
    color: var(--color-white-light);
}

.color-white {
    color: var(--color-white-medium);
}

.color-white-dark {
    color: var(--color-white-dark);
}

.color-blue-light {
    color: var(--color-blue-light);
}

tspan.color-blue-light {
    fill: var(--color-blue-light);
}

.color-blue {
    color: var(--color-blue-medium);
}

tspan.color-blue {
    fill: var(--color-blue-medium);
}

.color-blue-dark {
    color: var(--color-blue-dark);
}

tspan.color-blue-dark {
    fill: var(--color-blue-dark);
}

.color-green-light {
    color: var(--color-green-light);
}

.color-green {
    color: var(--color-green-medium);
}

tspan.color-green {
    fill: var(--color-green-medium);
}

.color-green-dark {
    color: var(--color-green-dark);
}

.color-purple-light {
    color: var(--color-purple-light);
}

.color-purple {
    color: var(--color-purple-medium);
}

tspan.color-purple {
    fill: var(--color-purple-medium);
}

.color-purple-dark {
    color: var(--color-purple-dark);
}

.color-birth-type-pink {
    color: var(--birth-type-pink);
}

.color-birth-type-orange {
    color: var(--birth-type-orange);
}

.color-birth-type-purple {
    color: var(--birth-type-purple);
}

.color-birth-type-blue {
    color: var(--birth-type-blue);
}
