:root {
    --color-dark-base: #493350;
    --color-lightgray-base: #c6bac9;
    --color-gray-base: #6c757d;
    --color-brown-base: #53442d;
    --color-sand-base: #af946a;
    --color-lime-base: #d9e021;
    --color-pink-base: #d3307d;
    --color-lightpink-base: #f7e3ec;
    --color-white-light: #ffffff;
    --color-white-medium: #f5f3f0;
    --color-white-dark: #e4dbcd;
    --color-blue-light: #d0dbe5;
    --color-blue-medium: #7077a0;
    --color-blue-dark: #2c4c8f;
    --color-green-light: #b5ded9;
    --color-green-medium: #67b6af;
    --color-green-dark: #16695e;
    --color-purple-light: #e2cae2;
    --color-purple-medium: #a66ca7;
    --color-purple-dark: #5e2060;
    --birth-type-pink: #ed6c8e;
    --birth-type-orange: #f28a30;
    --birth-type-purple: #814899;
    --birth-type-blue: #7f8bc3;
}

* {
    border: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font: inherit;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
}

html {
    display: table;
    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    background-color: var(--color-white-medium);
    display: table-cell;
    height: 100%;
    position: absolute;
    width: 100%;
}
