@media only screen and (max-width: 600px) {
    #calculator-form-text h4 {
        margin-bottom: 1.5vh;
    }

    #calculator-form-text p {
        margin-bottom: 5vh;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-form-text h4 {
        margin-bottom: 1.5vh;
    }

    #calculator-form-text p {
        margin-bottom: 4vh;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-form-text h4 {
        margin-bottom: 2vh;
    }

    #calculator-form-text p {
        margin-bottom: 0;
    }
}
