#blog-page {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 2.5vh 0;
    width: 100vw;
}

#blog-page-content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top: 2px solid var(--color-white-dark);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    #blog-page-content {
        margin-top: 5vh;
        min-height: 100vh;
        padding: 3.75vh 0;
        width: 85vw;
    }

    #blog-page-content h2 {
        margin-bottom: 5vh;
    }
}

@media only screen and (min-width: 600px) {
    #blog-page-content {
        margin-top: 5vh;
        min-height: 100vh;
        padding: 3.75vh 0;
        width: 80vw;
    }

    #blog-page-content h2 {
        margin-bottom: 5vh;
    }
}

@media only screen and (min-width: 768px) {
    #blog-page-content {
        width: 65vw;
    }
}

@media only screen and (min-width: 992px) {
    #blog-page-content {
        width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    #blog-page-content {
        min-height: 85vh;
        width: 75vw;
    }

    #blog-page-content h2 {
        margin-bottom: 0;
        min-width: 12em;
        width: 50%;
    }
}
