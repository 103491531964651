p {
    cursor: default;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    p {
        font-size: 0.9em;
        line-height: 1.35em;
    }

    .p-size-big {
        font-size: 1em;
        line-height: 1.45em;
    }

    .p-size-medium {
        font-size: 0.85em;
        line-height: 1.45em;
    }

    .p-size-small {
        font-size: 0.75em;
        line-height: 1.5em;
    }

    .p-size-very-small {
        font-size: 0.7em;
    }
}

@media only screen and (min-width: 600px) {
    p {
        font-size: 1.15em;
        line-height: 1.35em;
    }

    .p-size-big {
        font-size: 1.3em;
        line-height: 1.6em;
    }

    .p-size-medium {
        font-size: 1.3em;
        line-height: 1.6em;
    }

    .p-size-small {
        font-size: 1em;
        line-height: 1.5em;
    }

    .p-size-very-small {
        font-size: 0.85em;
    }
}

@media only screen and (min-width: 768px) {
    p {
        font-size: 1.25em;
    }

    .p-size-big {
        font-size: 1.4em;
        line-height: 1.65em;
    }

    .p-size-medium {
        font-size: 1.4em;
        line-height: 1.65em;
    }

    .p-size-small {
        font-size: 1.1em;
    }

    .p-size-very-small {
        font-size: 0.95em;
    }
}

@media only screen and (min-width: 992px) {
    p {
        font-size: 1.75em;
    }

    .p-size-big {
        font-size: 1.9em;
    }

    .p-size-medium {
        font-size: 1.75em;
    }

    .p-size-small {
        font-size: 1.5em;
    }

    .p-size-very-small {
        font-size: 1.4em;
    }
}

@media only screen and (min-width: 1200px) {
    p {
        font-size: 1.125em;
    }

    .p-size-big {
        font-size: 1.5em;
        line-height: 1.8em;
    }

    .p-size-medium {
        font-size: 1.25em;
        line-height: 1.6em;
    }

    .p-size-small {
        font-size: 0.975em;
    }

    .p-size-very-small {
        font-size: 0.825em;
    }
}
