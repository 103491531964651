#gestational-age-inputs .number-input-wrapper,
#gestational-age-inputs .number-input-inner-wrapper {
    display: inline-block;
}

#gestational-age-inputs span.number-input-label {
    display: block;
}

#calculator-form-inputs .button-fill-disable {
    background-color: var(--color-white-dark);
    border-color: var(--color-white-dark);
    opacity: 75%;
}

@media only screen and (max-width: 600px) {
    #calculator-form-inputs .date-input-wrapper,
    #calculator-form-inputs .number-input-wrapper {
        margin-top: 2.5vh;
    }

    #calculator-form-inputs .date-input-wrapper:first-child {
        margin-top: 0;
    }

    #calculator-form-inputs button {
        margin: 3.125vh 0 2vh 0;
    }

    #calculator-form-inputs p.p-size-very-small {
        margin: 0 auto;
        width: 90%;
    }

    #gestational-age-inputs .number-input-wrapper:first-child {
        margin-right: 3vw;
    }

    #gestational-age-inputs .number-input-inner-wrapper {
        margin-bottom: 1.25vh;
    }

    #gestational-age-inputs .number-input {
        font-size: 1em;
    }

    #gestational-age-inputs .number-input-side-text {
        font-size: 1em;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-form-inputs .date-input-wrapper,
    #calculator-form-inputs .number-input-wrapper {
        margin-top: 2.5vh;
    }

    #calculator-form-inputs .date-input-wrapper:first-child {
        margin-top: 0;
    }

    #calculator-form-inputs button {
        margin: 3.125vh 0 1.5vh 0;
    }

    #calculator-form-inputs p.p-size-very-small {
        margin: 0 auto;
        width: 85%;
    }

    #gestational-age-inputs .number-input-wrapper:first-child {
        margin-right: 3vw;
    }

    #gestational-age-inputs .number-input-inner-wrapper {
        margin-bottom: 0.75vh;
    }

    #gestational-age-inputs .number-input {
        font-size: 1.4em;
    }

    #gestational-age-inputs .number-input-side-text {
        font-size: 1.4em;
    }
}

@media only screen and (min-width: 768px) {
    #gestational-age-inputs .number-input {
        font-size: 1.7em;
    }

    #gestational-age-inputs .number-input-side-text {
        font-size: 1.7em;
    }
}

@media only screen and (min-width: 992px) {
    #calculator-form-inputs p.p-size-very-small {
        width: 90%;
    }

    #gestational-age-inputs .number-input {
        font-size: 2.25em;
    }

    #gestational-age-inputs .number-input-side-text {
        font-size: 2.25em;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-form-inputs {
        margin: auto 0;
    }

    #calculator-form-inputs .date-input-wrapper,
    #calculator-form-inputs .number-input-wrapper {
        margin-top: 2.25vh;
    }

    #calculator-form-inputs .date-input-wrapper:first-child {
        margin-top: 0;
    }

    #calculator-form-inputs p.p-size-very-small {
        margin: 0 auto;
        width: 80%;
    }

    #gestational-age-inputs .number-input-wrapper:first-child {
        margin-right: 1vw;
    }

    #gestational-age-inputs .number-input {
        font-size: 1.25em;
    }

    #gestational-age-inputs .number-input-side-text {
        font-size: 1.25em;
    }

    #calculator-form-inputs button {
        font-size: 1em;
    }
}
