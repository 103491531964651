#extras-findings-view {
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
}

#extras-findings-content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#extras-findings-title {
    background-color: var(--color-blue-light);
}

#extras-findings-text .superscript {
    color: var(--color-purple-dark);
    font-size: 70%;
    font-weight: 700;
    line-height: normal;
    vertical-align: super;
}

@media only screen and (max-width: 600px) {
    #extras-findings-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 85vh;
    }

    #extras-findings-image {
        display: none;
    }

    #extras-findings-title {
        padding: 7vh 12.5vw 3.5vh 7.5vw;
        width: 95vw;
    }

    #extras-findings-text {
        border-left: 1px solid var(--color-purple-medium);
        margin: -1.5vh 5vw 0 7.5vw;
        padding: 5vh 0 3.5vh 5vw;
        width: 85vw;
    }

    #extras-findings-content p {
        margin-bottom: 2.5vh;
    }

    #extras-findings-content p:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 600px) {
    #extras-findings-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 85vh;
    }

    #extras-findings-image {
        display: none;
    }

    #extras-findings-title {
        padding: 7vh 12.5vw 3.5vh 7.5vw;
        width: 92.5vw;
    }

    #extras-findings-text {
        border-left: 2px solid var(--color-purple-medium);
        padding: 5vh 0 3.5vh 5vw;
        margin: -1.5vh 5vw 0 7.5vw;
        width: 80vw;
    }

    #extras-findings-content p {
        margin-bottom: 2.5vh;
    }

    #extras-findings-content p:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) {
    #extras-findings-title {
        width: 85vw;
    }

    #extras-findings-text {
        width: 65vw;
    }
}

@media only screen and (min-width: 992px) {
    #extras-findings-text {
        width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    #extras-findings-title {
        width: unset;
    }

    #extras-findings-text {
        width: unset;
    }

    #extras-findings-view {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 85vh;
        padding: 0 10vw;
    }

    #extras-findings-image {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: auto;
        width: auto;
        padding-top: 5vh;
        width: 30vw;
    }

    #extras-findings-image img {
        height: auto;
        max-height: 85vh;
        max-width: 30vw;
        width: auto;
    }

    #extras-findings-content {
        padding-top: 5vh;
    }

    #extras-findings-title {
        padding: 10vh 7.5vw 7.5vh 2.5vw;
        width: 40vw;
    }

    #extras-findings-text {
        border-left: 3px solid var(--color-purple-medium);
        padding: 7.5vh 0 3.5vh 2.5vw;
        margin: -3vh 0 0 2.5vw;
        max-width: 36em;
        width: 36vw;
    }
}
