#results-exam-modal-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(73, 51, 80, 0.7);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: -2.5vh;
    position: absolute;
    z-index: 1;
}

#results-exam-modal {
    background-color: var(--color-lightpink-base);
    -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.15);
    color: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
    text-align: center;
}

#results-exam-modal-title {
    font-weight: 700;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    #results-exam-modal-wrapper {
        padding: 0 5vw;
    }

    #results-exam-modal {
        font-size: 0.85em;
        line-height: 1.25em;
        padding: 5vh 7.5vw;
    }

    #results-exam-modal-title {
        margin-bottom: 1.5em;
    }
}

@media only screen and (min-width: 600px) {
    #results-exam-modal-wrapper {
        padding: 0 12.5vw;
    }

    #results-exam-modal {
        font-size: 1.1em;
        line-height: 1.4em;
        padding: 5vh 7.5vw;
    }

    #results-exam-modal-title {
        margin-bottom: 1.5em;
    }
}

@media only screen and (min-width: 768px) {
    #results-exam-modal-wrapper {
        padding: 0 17.5vw;
    }

    #results-exam-modal {
        font-size: 1.25em;
        padding: 5vh 5vw;
    }

    #results-exam-modal-title {
        margin-bottom: 1.5em;
    }
}

@media only screen and (min-width: 992px) {
    #results-exam-modal-wrapper {
        padding: 0 22.5vw;
    }

    #results-exam-modal {
        font-size: 1.35em;
    }
}

@media only screen and (min-width: 1200px) {
    #results-exam-modal-wrapper {
        display: none;
    }
}
