button {
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    text-align: center;
    transition: 0.15s;
}

.button-border-sand {
    border: 1px solid var(--color-sand-base);
}

.button-border-blue-dark {
    border: 1px solid var(--color-blue-dark);
}

.button-border-purple {
    border: 1px solid var(--color-purple-medium);
}

.button-fill-dark {
    background-color: var(--color-dark-base);
    color: var(--color-lime-base);
}

.button-fill-dark:hover {
    background-color: var(--color-lime-base);
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
    color: var(--color-dark-base);
}

.button-fill-lime {
    background-color: var(--color-lime-base);
    color: var(--color-dark-base);
}

.button-fill-lime:hover {
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
    color: var(--color-white-medium);
}

.button-fill-white {
    background-color: var(--color-white-light);
    color: var(--color-blue-dark);
}

.button-fill-white:hover {
    background-color: var(--color-blue-dark);
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
    color: var(--color-white-light);
}

.button-fill-white-dark {
    background-color: var(--color-white-dark);
    color: var(--color-brown-base);
}

.button-fill-white-dark:hover {
    box-shadow: 4px 6px 8px rgba(0, 0, 0, 0.15);
    color: var(--color-white-medium);
}

.button-fill-none {
    background-color: transparent;
    box-shadow: none;
    text-decoration: underline;
}

.button-fill-disable {
    background-color: var(--color-white-dark);
    color: var(--color-lightgray-base);
    cursor: not-allowed;
    opacity: 80%;
}

.button-width-full {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    button {
        font-size: 0.9em;
        padding: 0.75em 1.5em;
    }
}

@media only screen and (min-width: 600px) {
    button {
        font-size: 1.15em;
        padding: 0.75em 1.5em;
    }
}

@media only screen and (min-width: 768px) {
    button {
        font-size: 1.25em;
    }
}

@media only screen and (min-width: 992px) {
    button {
        font-size: 1.75em;
    }
}

@media only screen and (min-width: 1200px) {
    button {
        font-size: 1.125em;
        padding: 1.25em 2.5em;
    }
}
