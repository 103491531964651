#calculator-form-view {
    min-height: 100vh;
    width: 100vw;
}

#calculator-form-view,
#calculator-form-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#calculator-form-view.method-lpd {
    background-color: var(--color-purple-light);
}

#calculator-form-view.method-fed {
    background-color: var(--color-blue-light);
}

#calculator-form-container {
    background-color: var(--color-white-medium);
    border: 1px dashed var(--color-dark-base);
}

.method-lpd #calculator-form-content > button:last-of-type {
    color: var(--color-purple-dark);
}

.method-fed #calculator-form-content > button:last-of-type {
    color: var(--color-blue-dark);
}

@media only screen and (max-width: 600px) {
    #calculator-form-container {
        margin: 4.5vh 0 5.25vh 0;
        padding: 3.5vh 6.5vw;
        width: 90vw;
    }

    #calculator-form-content > button:last-of-type {
        font-size: 0.75em;
        margin-bottom: 2.5vh;
        min-width: 60vw;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-form-container {
        margin: 8.75vh 0 5.25vh 0;
        padding: 3.5vh 6.5vw;
        width: 85vw;
    }

    #calculator-form-content > button {
        min-width: 50vw;
    }

    #calculator-form-content > button:last-of-type {
        font-size: 1em;
        margin: 0.5vh 0 6.5vh 0;
    }
}

@media only screen and (min-width: 768px) {
    #calculator-form-container {
        width: 80vw;
    }

    #calculator-form-content > button:last-of-type {
        font-size: 1.25em;
        margin: 0.75vh 0 6.5vh 0;
    }
}

@media only screen and (min-width: 992px) {
    #calculator-form-container {
        width: 75vw;
    }

    #calculator-form-content > button:last-of-type {
        font-size: 1.75em;
        margin: 0.5vh 0 6.5vh 0;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-form-container {
        display: -ms-grid;
        display: grid;
    }

    #calculator-form-container {
        gap: 6.25vw;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 6.25vw 2fr;
        grid-template-columns: 1fr 2fr;
        margin-bottom: 6.5vh;
        min-width: 60em;
        padding: 8vh 5.5vw;
        width: 55vw;
    }

    #calculator-form-content > button {
        min-width: 25vw;
    }

    #calculator-form-content > button:last-of-type {
        font-size: 1em;
        margin: 0.25em 0 6.5vh 0;
    }
}
