#results-mobile-content {
    background-color: var(--color-white-light);
    color: var(--color-dark-base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    overflow: auto;
    width: 100vw;
}

#results-mb-content-title {
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
    text-align: left;
}

#results-mb-content-date {
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 500;
    text-align: left;
}

#results-mb-content-date::before {
    content: '\01F4C5\0020';
}

#results-mb-content-body {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    text-align: left;
}

#results-exam-mb-disclaimer {
    cursor: pointer;
    color: var(--color-blue-dark);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    text-decoration: underline;
}

#results-exam-mb-disclaimer .info-square-icon {
    background-image: url('../../../assets/icons/info-square-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

#results-page.step-0-hide-today #results-mobile-content,
#results-page.step-0 #results-mobile-content,
#results-page.step-1-hide-today #results-mobile-content,
#results-page.step-1 #results-mobile-content,
#results-page.step-2-hide-today #results-mobile-content,
#results-page.step-2 #results-mobile-content,
#results-page.step-3-hide-today #results-mobile-content,
#results-page.step-3 #results-mobile-content,
#results-page.step-4-hide-today #results-mobile-content,
#results-page.step-4 #results-mobile-content,
#results-page.step-12-hide-today #results-mobile-content,
#results-page.step-13 #results-mobile-content,
#results-page.step-13-hide-today #results-mobile-content,
#results-page.step-14 #results-mobile-content,
#results-page.step-14-hide-today #results-mobile-content,
#results-page.step-15 #results-mobile-content,
#results-page.step-15-hide-today #results-mobile-content,
#results-page.step-16 #results-mobile-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#results-page.step-4-hide-today #results-mb-content-date,
#results-page.step-4 #results-mb-content-date,
#results-page.step-5-hide-today #results-mb-content-date,
#results-page.step-5 #results-mb-content-date {
    display: none;
}

.highlight-text-blue-light,
.highlight-text-green-light,
.highlight-text-purple-light,
.highlight-text-blue,
.highlight-text-blue-dark,
.highlight-text-birth-type-blue {
    border-radius: 0.25em;
    padding: 0 0.25em;
}

.highlight-text-blue-light {
    background-color: var(--color-blue-light);
}

.highlight-text-green-light {
    background-color: var(--color-green-light);
}

.highlight-text-purple-light {
    background-color: var(--color-purple-light);
}

.highlight-text-blue {
    background-color: var(--color-blue-medium);
    color: var(--color-white-light);
}

.highlight-text-blue-dark {
    background-color: var(--color-blue-dark);
    color: var(--color-white-light);
}

.highlight-text-birth-type-blue {
    background-color: var(--birth-type-blue);
    color: var(--color-white-light);
}

@media only screen and (max-width: 600px) {
    #results-mobile-content {
        font-size: 0.95em;
        height: 36.5vh;
        line-height: 1.4em;
        padding: 2vh 6.25vw 1vh 6.25vw;
    }

    #results-mb-content-title {
        font-size: 1.25em;
        margin-bottom: 0.25em;
    }

    #results-mb-content-date {
        font-size: 0.775em;
        line-height: 1.75em;
        margin-bottom: 0.5em;
        width: 80%;
    }

    #results-mb-content-body {
        font-size: 0.85em;
        line-height: 1.5em;
    }

    #results-exam-mb-disclaimer {
        font-size: 0.95em;
        line-height: 1.5em;
        margin-top: 2.5em;
    }

    #results-exam-mb-disclaimer .info-square-icon {
        margin-right: 0.25em;
        padding-left: 1.75em;
    }

    #results-page.step-0-hide-today #results-mobile-content,
    #results-page.step-0 #results-mobile-content,
    #results-page.step-1-hide-today #results-mobile-content,
    #results-page.step-1 #results-mobile-content,
    #results-page.step-2-hide-today #results-mobile-content,
    #results-page.step-2 #results-mobile-content,
    #results-page.step-3-hide-today #results-mobile-content,
    #results-page.step-3 #results-mobile-content,
    #results-page.step-4-hide-today #results-mobile-content,
    #results-page.step-4 #results-mobile-content {
        font-size: 1.25em;
        line-height: 1.5em;
    }

    #results-page.step-5-hide-today #results-mb-content-title,
    #results-page.step-5 #results-mb-content-title {
        margin-bottom: 0.5em;
    }

    #results-page.step-14-hide-today #results-mobile-content div:nth-child(1),
    #results-page.step-15 #results-mobile-content div:nth-child(1) {
        margin-bottom: 1.25em;
    }
}

@media only screen and (min-width: 600px) {
    #results-mobile-content {
        font-size: 1.4em;
        height: 37.5vh;
        line-height: 1.4em;
        padding: 2.5vh 10vw 2.5vh 10vw;
    }

    #results-mb-content-title {
        font-size: 1.25em;
        margin-bottom: 0.25em;
    }

    #results-mb-content-date {
        font-size: 0.825em;
        line-height: 1.5em;
        margin-bottom: 1.15em;
        width: 90%;
    }

    #results-mb-content-body {
        font-size: 0.8em;
        line-height: 1.4em;
        width: 90%;
    }

    #results-exam-mb-disclaimer {
        font-size: 0.9em;
        margin-top: 2em;
    }

    #results-exam-mb-disclaimer .info-square-icon {
        margin-right: 0.35em;
        padding-left: 1.75em;
    }

    #results-page.step-0-hide-today #results-mobile-content,
    #results-page.step-0 #results-mobile-content,
    #results-page.step-1-hide-today #results-mobile-content,
    #results-page.step-1 #results-mobile-content,
    #results-page.step-2-hide-today #results-mobile-content,
    #results-page.step-2 #results-mobile-content,
    #results-page.step-3-hide-today #results-mobile-content,
    #results-page.step-3 #results-mobile-content,
    #results-page.step-4-hide-today #results-mobile-content,
    #results-page.step-4 #results-mobile-content {
        font-size: 1.75em;
        line-height: 1.5em;
    }

    #results-page.step-5-hide-today #results-mb-content-title,
    #results-page.step-5 #results-mb-content-title {
        margin-bottom: 0.75em;
    }

    #results-page.step-14-hide-today #results-mobile-content div:nth-child(1),
    #results-page.step-15 #results-mobile-content div:nth-child(1) {
        margin-bottom: 1em;
    }
}

@media only screen and (min-width: 768px) {
    #results-mobile-content {
        font-size: 1.5em;
        height: 38vh;
        line-height: 1.4em;
        padding: 2.5vh 12.5vw 2.5vh 12.5vw;
    }

    #results-mb-content-title {
        font-size: 1.4em;
        margin-bottom: 0.3em;
    }

    #results-mb-content-date {
        font-size: 0.85em;
        line-height: 1.5em;
        margin-bottom: 0.75em;
    }

    #results-mb-content-body {
        font-size: 0.825em;
        line-height: 1.4em;
        width: 90%;
    }

    #results-page.step-0-hide-today #results-mobile-content,
    #results-page.step-0 #results-mobile-content,
    #results-page.step-1-hide-today #results-mobile-content,
    #results-page.step-1 #results-mobile-content,
    #results-page.step-2-hide-today #results-mobile-content,
    #results-page.step-2 #results-mobile-content,
    #results-page.step-3-hide-today #results-mobile-content,
    #results-page.step-3 #results-mobile-content,
    #results-page.step-4-hide-today #results-mobile-content,
    #results-page.step-4 #results-mobile-content {
        font-size: 1.75em;
        line-height: 1.5em;
    }

    #results-page.step-5-hide-today #results-mb-content-title,
    #results-page.step-5 #results-mb-content-title {
        margin-bottom: 0.75em;
    }

    #results-page.step-14-hide-today #results-mobile-content div:nth-child(1),
    #results-page.step-15 #results-mobile-content div:nth-child(1) {
        margin-bottom: 1em;
    }
}

@media only screen and (min-width: 992px) {
    #results-mobile-content {
        font-size: 2.25em;
        height: 38vh;
        line-height: 1.5em;
        padding: 2vh 15vw 2vh 15vw;
    }

    #results-mb-content-title {
        font-size: 1.15em;
        margin-bottom: 0.25em;
    }

    #results-mb-content-date {
        font-size: 0.75em;
        line-height: 1.5em;
        margin-bottom: 0.5em;
    }

    #results-mb-content-body {
        font-size: 0.75em;
        line-height: 1.4em;
        width: 90%;
    }

    #results-exam-mb-disclaimer {
        font-size: 0.9em;
        margin-top: 1.9em;
    }

    #results-exam-mb-disclaimer .info-square-icon {
        margin-right: 0.45em;
        padding-left: 2em;
    }

    #results-page.step-0-hide-today #results-mobile-content,
    #results-page.step-0 #results-mobile-content,
    #results-page.step-1-hide-today #results-mobile-content,
    #results-page.step-1 #results-mobile-content,
    #results-page.step-2-hide-today #results-mobile-content,
    #results-page.step-2 #results-mobile-content,
    #results-page.step-3-hide-today #results-mobile-content,
    #results-page.step-3 #results-mobile-content,
    #results-page.step-4-hide-today #results-mobile-content,
    #results-page.step-4 #results-mobile-content {
        font-size: 2.25em;
        line-height: 1.5em;
    }

    #results-page.step-5-hide-today #results-mb-content-title,
    #results-page.step-5 #results-mb-content-title {
        margin-bottom: 0.5em;
    }

    #results-page.step-14-hide-today #results-mobile-content div:nth-child(1),
    #results-page.step-15 #results-mobile-content div:nth-child(1) {
        margin-bottom: 0.95em;
    }
}

@media only screen and (min-width: 1200px) {
    #results-mobile-content {
        display: none;
    }
}
