h5 {
    cursor: default;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    h5 {
        font-size: 1.25em;
        line-height: 1.25em;
    }
}

@media only screen and (min-width: 600px) {
    h5 {
        font-size: 1.75em;
        line-height: 1.25em;
    }
}

@media only screen and (min-width: 768px) {
    h5 {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 992px) {
    h5 {
        font-size: 2.75em;
    }
}

@media only screen and (min-width: 1200px) {
    h5 {
        font-size: 1.5em;
    }
}
