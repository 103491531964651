.nav-mb-buttons button:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--color-brown-base);
}

@media only screen and (max-width: 600px) {
    .nav-mb-buttons {
        width: 100vw;
    }

    .nav-mb-buttons button:nth-child(1) {
        border-right: none;
        height: 7.5vh;
        text-align: left;
        width: 50vw;
    }

    .nav-mb-buttons button:nth-child(2) {
        height: 7.5vh;
        text-align: right;
        width: 50vw;
    }

    .nav-buttons {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    .nav-mb-buttons {
        width: 100vw;
    }

    .nav-mb-buttons button:nth-child(1) {
        border-right: none;
        height: 6vh;
        text-align: left;
        width: 50vw;
    }

    .nav-mb-buttons button:nth-child(2) {
        height: 6vh;
        text-align: right;
        width: 50vw;
    }

    .nav-buttons {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .nav-mb-buttons {
        display: none;
    }

    .nav-buttons {
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 12vh;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 2.5vh 2.5vw;
        position: absolute;
        width: 100vw;
        z-index: 100;
    }

    .nav-buttons button {
        background-color: transparent;
        height: 7vh;
        width: 7vh;
        padding: 0.75em 1em;
    }

    .nav-buttons button:hover {
        background-color: var(--color-blue-dark);
    }
}
