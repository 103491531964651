#extras-final-view {
    background-color: var(--color-blue-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
}

#extras-final-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    #extras-final-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 87.5vh;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #extras-final-content {
        margin: auto 0;
        width: 85vw;
    }

    #extras-final-content .text-highlight {
        font-weight: 700;
    }

    #extras-final-content p:first-child {
        margin-bottom: 3vh;
    }

    #extras-final-content button {
        margin-top: 6vh;
        min-width: 90%;
        padding: 0.75em 0.9em;
    }

    #extras-final-image {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 22.5vh;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-bottom: -2.5vh;
        width: 100vw;
    }

    #extras-final-image img {
        height: auto;
        max-height: 22.5vh;
        width: auto;
    }

    #extras-final-image.without-results {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    #extras-final-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 90vh;
    }

    #extras-final-content {
        margin: auto 0;
        width: 80vw;
    }

    #extras-final-content p:first-child {
        margin-bottom: 3.5vh;
    }

    #extras-final-content button {
        margin: 7.5vh 0;
        min-width: 85%;
    }

    #extras-final-image {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 40vh;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-bottom: -2.5vh;
        width: 100vw;
    }

    #extras-final-image img {
        height: auto;
        max-height: 40vh;
        width: auto;
    }

    #extras-final-image.without-results {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    #extras-final-content {
        width: 65vw;
    }

    #extras-final-content button {
        margin: 6vh 0;
        min-width: 90%;
    }

    #extras-final-image {
        height: 35vh;
    }

    #extras-final-image img {
        max-height: 35vh;
    }

    #extras-final-image.without-results {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    #extras-final-content {
        width: 60vw;
    }

    #extras-final-content button {
        margin: 6vh 0;
        min-width: 80%;
    }

    #extras-final-image {
        height: 25vh;
    }

    #extras-final-image img {
        max-height: 25vh;
    }

    #extras-final-image.without-results {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    #extras-final-view {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding-left: 5vw;
    }

    #extras-final-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 85vh;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: auto 0;
        width: 40vw;
    }

    #extras-final-content p {
        font-weight: 300;
    }

    #extras-final-content p:first-child {
        margin-bottom: 3.5vh;
    }

    #extras-final-content button {
        margin: 7vh 0;
        min-width: 75%;
    }

    #extras-final-image,
    #extras-final-image.without-results {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 97.5vh;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin: auto 0 -2.5vh 0;
        width: 45vw;
    }

    #extras-final-image img {
        height: auto;
        max-height: unset;
        width: 45vw;
    }
}
