#calculator-form-hint-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
}

#calculator-form-hint {
    background-color: var(--color-white-light);
    border: 1px solid var(--color-blue-dark);
    -webkit-box-shadow: 4px 8px 2px rgba(0, 0, 0, 0.075);
    box-shadow: 4px 8px 2px rgba(0, 0, 0, 0.075);
}

#calculator-form-hint p {
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    #calculator-form-hint-wrapper {
        background-color: rgba(73, 51, 80, 0.6);
        padding: 0 2.5vw;
    }

    #calculator-form-hint {
        padding: 3.5vh 6.25vw 2.75vh 6.25vw;
        text-align: center;
    }

    #calculator-form-hint p {
        font-family: 'IBM Plex Mono', sans-serif;
        margin-bottom: 2.75vh;
    }

    #calculator-form-hint button {
        margin-bottom: 0 !important;
        width: 50%;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-form-hint-wrapper {
        background-color: rgba(73, 51, 80, 0.6);
        padding: 0 10vw;
    }

    #calculator-form-hint {
        padding: 3.5vh 6.25vw 2.75vh 6.25vw;
        text-align: center;
    }

    #calculator-form-hint p {
        font-family: 'IBM Plex Mono', sans-serif;
        margin-bottom: 2.75vh;
    }

    #calculator-form-hint button {
        margin-bottom: 0 !important;
        width: 50%;
    }
}

@media only screen and (min-width: 768px) {
    #calculator-form-hint-wrapper {
        padding: 0 15vw;
    }
}

@media only screen and (min-width: 992px) {
    #calculator-form-hint-wrapper {
        padding: 0 20vw;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-form-hint-wrapper {
        background-color: transparent;
        left: calc(50vw + 25vw - 2vw);
        padding: 17.5vh 0 0 0;
        pointer-events: none;
    }

    #calculator-form-hint {
        padding: 2vh 1.25vw;
        min-width: 15em;
        width: 13vw;
    }

    #calculator-form-hint p {
        font-family: 'IBM Plex Mono', sans-serif;
        margin-bottom: 1.5em;
    }

    #calculator-form-hint p:nth-child(2) {
        margin-bottom: 0;
    }

    #calculator-form-hint button {
        display: none;
    }
}
