.button-icon-lime-down-arrow,
.button-icon-brown-left-arrow,
.button-icon-brown-right-arrow,
.button-icon-pink-left-arrow,
.button-icon-pink-right-arrow {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
}

.button-icon-lime-down-arrow {
    background-image: url('../../../assets/icons/lime-down-arrow.svg');
}

.icon-button:hover .button-icon-lime-down-arrow {
    background-image: url('../../../assets/icons/dark-down-arrow.svg');
}

.button-icon-brown-left-arrow {
    background-image: url('../../../assets/icons/brown-left-arrow.svg');
}

.button-icon-brown-right-arrow {
    background-image: url('../../../assets/icons/brown-right-arrow.svg');
}

.button-icon-pink-left-arrow {
    background-image: url('../../../assets/icons/pink-left-arrow.svg');
}

.button-icon-pink-right-arrow {
    background-image: url('../../../assets/icons/pink-right-arrow.svg');
}

.icon-button:hover .button-icon-pink-left-arrow {
    background-image: url('../../../assets/icons/white-left-arrow.svg');
}

.icon-button:hover .button-icon-pink-right-arrow {
    background-image: url('../../../assets/icons/white-right-arrow.svg');
}

@media only screen and (max-width: 600px) {
    .icon-button span {
        padding-left: 1em;
    }

    .left-icon {
        margin-right: 0.9em;
    }

    .right-icon {
        margin-left: 0.9em;
    }
}

@media only screen and (min-width: 600px) {
    .icon-button span {
        padding-left: 1.25em;
    }

    .left-icon {
        margin-right: 1.15em;
    }

    .right-icon {
        margin-left: 1.15em;
    }
}

@media only screen and (min-width: 768px) {
    .icon-button span {
        padding-left: 1.35em;
    }

    .left-icon {
        margin-right: 1em;
    }

    .right-icon {
        margin-left: 1em;
    }
}

@media only screen and (min-width: 992px) {
    .icon-button span {
        padding-left: 1.85em;
    }

    .left-icon {
        margin-right: 1.75em;
    }

    .right-icon {
        margin-left: 1.75em;
    }
}

@media only screen and (min-width: 1200px) {
    .icon-button span {
        padding-left: 1.3em;
    }

    .left-icon {
        margin-right: 1.125em;
    }

    .right-icon {
        margin-left: 1.125em;
    }
}
