#extras-births-by-day-view {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100vw;
}

#extras-births-by-day-chart-title {
    border-bottom: 3px solid var(--color-white-dark);
    color: var(--color-dark-base);
    cursor: help;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    text-align: center;
}

#births-by-day-chart-y-axis path,
#births-by-day-chart-y-axis .tick line {
    display: none;
}

#births-by-day-chart-y-axis-title {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 500;
    text-transform: uppercase;
}

#births-by-day-chart-y-axis text {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
}

#births-by-day-chart-svg .birth-day-marker line {
    fill: var(--color-dark-base);
    stroke: var(--color-dark-base);
    stroke-dasharray: 12 6;
}

#births-by-day-chart-svg .birth-day-marker text {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
}

#births-by-day-chart-mb-legend-labels {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 800;
    text-anchor: end;
}

#births-by-day-chart-mb-legend-day {
    fill: var(--color-blue-medium);
}

#births-by-day-chart-mb-legend-week {
    fill: var(--color-green-medium);
}

#births-by-day-chart-mb-legend-month {
    fill: var(--color-purple-medium);
}

.births-by-day-chart-legend-label {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
}

#births-by-day-chart-legend-day {
    fill: var(--color-blue-light);
}

#births-by-day-chart-legend-week {
    fill: var(--color-green-light);
}

#births-by-day-chart-legend-month {
    fill: var(--color-purple-light);
}

.births-by-day-chart-floating-wrapper {
    fill: var(--color-white-light);
    -webkit-filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
    filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
}

.births-by-day-chart-floating-text {
    fill: var(--color-dark-base);
    font-family: 'Nunito', sans-serif;
    font-style: italic;
    font-weight: 700;
    text-anchor: middle;
}

#births-by-day-chart-mb-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-light);
    color: var(--color-dark-base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-family: 'Nunito', sans-serif;
    font-weight: 500;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: auto;
    width: 100vw;
}

.births-by-day-day-bar {
    fill: var(--color-blue-light);
}

.births-by-day-ghost-bar {
    fill: var(--color-blue-light);
}

.births-by-day-week-bar {
    fill: var(--color-green-light);
}

.births-by-day-month-bar {
    fill: var(--color-purple-light);
}

.highlighted-line-day {
    fill: var(--color-blue-dark);
}

.highlighted-large-day {
    fill: var(--color-blue-medium);
}

.highlighted-wide-day {
    fill: var(--color-white-light);
    stroke: var(--color-blue-dark);
}

.highlighted-day-lime {
    fill: var(--color-lime-base);
}

#extras-births-by-day-view .text-highlight-lime {
    background-color: var(--color-lime-base);
}

#extras-births-by-day-view .text-highlight-blue {
    background-color: var(--color-blue-medium);
    color: var(--color-white-light);
}

@media only screen and (max-width: 600px) {
    #extras-births-by-day-view {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 85vh;
    }

    #extras-births-by-day-chart-title {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 0.775em;
        height: 8.5vh;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 2.5vw;
        padding-bottom: 1.5vh;
        text-align: center;
        width: 95vw;
    }

    #births-by-day-chart-svg {
        height: 45vh;
        width: 100vw;
    }

    #births-by-day-chart-y-axis-title {
        display: none;
    }

    #births-by-day-chart-y-axis text {
        font-size: 2.5em;
    }

    #births-by-day-chart-gridlines {
        stroke: var(--color-dark-base);
        stroke-dasharray: 15 15;
    }

    #births-by-day-chart-svg .birth-day-marker text,
    #births-by-day-chart-mb-legend-labels {
        font-size: 1.875em;
    }

    .births-by-day-chart-legend,
    .births-by-day-chart-legend-label {
        display: none;
    }

    .births-by-day-chart-floating-wrapper,
    .births-by-day-chart-floating-text {
        display: none;
    }

    #births-by-day-chart-mb-content {
        font-size: 0.95em;
        height: 36.5vh;
        line-height: 1.4em;
        padding: 3vh 6.25vw 1vh 6.25vw;
    }
}

@media only screen and (min-width: 600px) {
    #extras-births-by-day-view {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 87.5vh;
    }

    #extras-births-by-day-chart-title {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        font-size: 1em;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 10vw;
        height: 8.5vh;
        padding-bottom: 1.5vh;
        text-align: center;
        width: 80vw;
    }

    #births-by-day-chart-svg {
        height: 45vh;
        width: 100vw;
    }

    #births-by-day-chart-y-axis-title {
        display: none;
    }

    #births-by-day-chart-y-axis text {
        font-size: 2.25em;
    }

    #births-by-day-chart-gridlines {
        stroke: var(--color-gray-base);
        stroke-dasharray: 10 10;
    }

    #births-by-day-chart-svg .birth-day-marker text,
    #births-by-day-chart-mb-legend-labels {
        font-size: 1.6em;
    }

    .births-by-day-chart-legend,
    .births-by-day-chart-legend-label {
        display: none;
    }

    .births-by-day-chart-floating-wrapper,
    .births-by-day-chart-floating-text {
        display: none;
    }

    #births-by-day-chart-mb-content {
        font-size: 1.4em;
        height: 37.5vh;
        line-height: 1.4em;
        padding: 5vh 10vw 2.5vh 10vw;
    }
}

@media only screen and (min-width: 768px) {
    #extras-births-by-day-chart-title {
        font-size: 1.2em;
        margin: 0 12.5vw;
        width: 75vw;
    }

    #births-by-day-chart-y-axis text {
        font-size: 1.75em;
    }

    #births-by-day-chart-gridlines {
        stroke-dasharray: 10 10;
    }

    #births-by-day-chart-svg .birth-day-marker text,
    #births-by-day-chart-mb-legend-labels {
        font-size: 1.3em;
    }

    #births-by-day-chart-mb-content {
        font-size: 1.5em;
        height: 38vh;
        line-height: 1.4em;
        padding: 5vh 12.5vw 2.5vh 12.5vw;
    }
}

@media only screen and (min-width: 992px) {
    #extras-births-by-day-chart-title {
        font-size: 1.6em;
    }

    #births-by-day-chart-y-axis text {
        font-size: 2em;
    }

    #births-by-day-chart-gridlines {
        stroke-dasharray: 10 10;
    }

    #births-by-day-chart-svg .birth-day-marker line {
        stroke-dasharray: 10 5;
    }

    #births-by-day-chart-svg .birth-day-marker text,
    #births-by-day-chart-mb-legend-labels {
        font-size: 1.05em;
    }

    #births-by-day-chart-mb-content {
        font-size: 2.25em;
        height: 38vh;
        line-height: 1.5em;
        padding: 4vh 15vw 2vh 15vw;
    }
}

@media only screen and (min-width: 1200px) {
    #extras-births-by-day-chart-title {
        font-size: 1.25em;
        margin: 0 10vw;
        padding: 1.5vh;
        width: 80vw;
    }

    #births-by-day-chart-svg {
        height: 82.5vh;
    }

    #births-by-day-chart-y-axis-title {
        display: unset;
        font-size: 0.9em;
    }

    #births-by-day-chart-y-axis text {
        font-size: 0.8em;
    }

    #births-by-day-chart-gridlines {
        stroke-dasharray: 5 5;
    }

    #births-by-day-chart-svg .birth-day-marker line {
        stroke-dasharray: 6 3;
    }

    #births-by-day-chart-svg .birth-day-marker text {
        font-size: 0.75em;
    }

    #births-by-day-chart-svg .birth-day-marker text {
        font-size: 0.75em;
    }

    .births-by-day-chart-legend,
    .births-by-day-chart-legend-label {
        display: unset;
    }

    .births-by-day-chart-legend-label {
        font-size: 0.75em;
        text-align: center;
    }

    .births-by-day-chart-floating-wrapper,
    .births-by-day-chart-floating-text {
        display: unset;
    }

    .births-by-day-chart-floating-text {
        font-size: 0.875em;
    }

    .births-by-day-chart-hint {
        font-size: 0.75em;
        padding: 1.5vh 1.25vw;
    }

    #births-by-day-chart-mb-legend-labels,
    #births-by-day-chart-mb-content {
        display: none;
    }
}
