#extras-page {
    background-color: var(--color-white-medium);
    min-height: 100vh;
    width: 100vw;
}

#extras-page.step-6 {
    background-color: var(--color-blue-medium);
}

#extras-page-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 600px) {
    #extras-page {
        padding-top: 2.5vh;
    }

    #extras-page-content {
        min-height: 90vh;
    }

    #extras-page-mb-buttons {
        width: 100vw;
    }

    #extras-page-mb-buttons button:nth-child(1) {
        border-right: none;
        height: 7.5vh;
        text-align: left;
        width: 50vw;
    }

    #extras-page-mb-buttons button:nth-child(2) {
        height: 7.5vh;
        text-align: right;
        width: 50vw;
    }

    #extras-page-buttons {
        display: none;
    }
}

@media only screen and (min-width: 600px) {
    #extras-page {
        padding-top: 2.5vh;
    }

    #extras-page-content {
        min-height: 91.5vh;
    }

    #extras-page-mb-buttons {
        width: 100vw;
    }

    #extras-page-mb-buttons button:nth-child(1) {
        border-right: none;
        height: 6vh;
        text-align: left;
        width: 50vw;
    }

    #extras-page-mb-buttons button:nth-child(2) {
        height: 6vh;
        text-align: right;
        width: 50vw;
    }

    #extras-page-buttons {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    #extras-page {
        padding: 2.5vh 0;
    }

    #extras-page-content {
        min-height: 95vh;
    }

    #extras-page-mb-buttons {
        display: none;
    }

    #extras-page-buttons {
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 12vh;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 2.5vh 2.5vw;
        position: absolute;
        width: 100vw;
        z-index: 100;
    }

    #extras-page-buttons button {
        background-color: transparent;
        height: 7vh;
        width: 7vh;
        padding: 0.75em 1em;
    }

    #extras-page-buttons button:hover {
        background-color: var(--color-blue-dark);
    }

    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(1),
    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(2) {
        border: 1px solid var(--color-pink-base);
    }

    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(1):hover,
    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(2):hover {
        background-color: var(--color-pink-base);
    }

    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(1) span {
        background-image: url('../../assets/icons/white-left-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80% 80%;
    }

    #extras-page.step-6 .nav-buttons .icon-button:nth-of-type(2) span {
        background-image: url('../../assets/icons/white-right-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80% 80%;
    }
}
