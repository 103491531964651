#calculator-results-view {
    background-color: var(--color-dark-base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2.5vh 0;
    min-height: 100vh;
    width: 100vw;
}

#calculator-results-image {
    margin-top: auto;
}

#calculator-results-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    #calculator-results-view,
    #calculator-results-text {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #calculator-results-image {
        display: none;
    }

    #calculator-results-text h1 {
        text-align: center;
        width: 90vw;
    }

    #calculator-results-text h4 {
        font-weight: 400;
        margin-bottom: 5vh;
        text-align: center;
        width: 85vw;
    }

    #calculator-results-text p {
        font-weight: 600;
        margin: 5vh auto 5vh auto;
        padding: 0 7.5%;
        text-align: center;
        width: 85vw;
    }

    #calculator-results-view button {
        max-width: 90vw;
        min-width: 85vw;
    }
}

@media only screen and (min-width: 600px) {
    #calculator-results-view,
    #calculator-results-text {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #calculator-results-image {
        display: none;
    }

    #calculator-results-text h1 {
        text-align: center;
        width: 85vw;
    }

    #calculator-results-text h4 {
        font-weight: 400;
        margin-bottom: 6vh;
        text-align: center;
        width: 80vw;
    }

    #calculator-results-text p {
        font-weight: 600;
        margin: 6vh auto 6vh auto;
        padding: 0 7.5%;
        text-align: center;
        width: 80vw;
    }

    #calculator-results-view button {
        max-width: 85vw;
        min-width: 80vw;
    }
}

@media only screen and (min-width: 768px) {
    #calculator-results-text h1 {
        width: 75vw;
    }

    #calculator-results-text h4 {
        padding: 0 5%;
        width: 65vw;
    }

    #calculator-results-text p {
        font-weight: 500;
        padding: 0 10%;
        width: 65vw;
    }

    #calculator-results-view button {
        max-width: 75vw;
        min-width: 65vw;
    }
}

@media only screen and (min-width: 992px) {
    #calculator-results-text h1 {
        width: 75vw;
    }

    #calculator-results-text h4 {
        padding: 0;
        width: 60vw;
    }

    #calculator-results-text p {
        padding: 0 10%;
        width: 60vw;
    }

    #calculator-results-view button {
        max-width: 75vw;
        min-width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    #calculator-results-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 0 0 7.5vw;
        width: 100vw;
    }

    #calculator-results-text {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 47.5vw;
    }

    #calculator-results-text h1 {
        font-size: 4.5em;
        text-align: left;
        width: unset;
    }

    #calculator-results-text h4 {
        display: unset;
        font-size: 2.5em;
        font-weight: 700;
        margin-bottom: 2.5vh;
        text-align: left;
        width: 100%;
    }

    #calculator-results-text p {
        font-weight: 400;
        line-height: 150%;
        margin: 6vh 0 4.5vh 0;
        padding: 0 40% 0 0;
        text-align: left;
        width: unset;
    }

    #calculator-results-view button {
        min-width: 32.5em;
        width: 35vw;
    }

    #calculator-results-image {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 100vh;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        width: 40vw;
    }

    #calculator-results-image img {
        height: auto;
        max-height: 80vh;
        max-width: 40vw;
        width: auto;
    }
}
