h1 {
    cursor: default;
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 2.5em;
        line-height: 1.15em;
    }
}

@media only screen and (min-width: 600px) {
    h1 {
        font-size: 3.25em;
        line-height: 1.15em;
    }
}

@media only screen and (min-width: 768px) {
    h1 {
        font-size: 3.75em;
    }
}

@media only screen and (min-width: 992px) {
    h1 {
        font-size: 5.25em;
    }
}

@media only screen and (min-width: 1200px) {
    h1 {
        font-size: 4.5em;
    }
}
