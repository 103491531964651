#references-page {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 2.5vh 0;
    width: 100vw;
}

#references-page h2 {
    text-align: center;
}

#references-page a {
    color: var(--color-green-medium);
    font-weight: 800;
}

#references-page p {
    line-height: 1.4em;
    text-align: justify;
}

#references-page-mb-buttons button:nth-child(2) {
    color: var(--color-white-dark);
    pointer-events: none;
}

#references-page-mb-buttons button:nth-child(2) span,
#references-page-buttons button:nth-child(2) {
    display: none;
}

@media only screen and (max-width: 600px) {
    #references-page {
        padding: 5vh 10vw 2.5vh 10vw;
    }

    #references-page h2 {
        margin-bottom: 3.75vh;
    }

    #references-page p {
        margin-bottom: 2.5vh;
    }
}

@media only screen and (min-width: 600px) {
    #references-page {
        padding: 5vh 10vw 2.5vh 10vw;
    }

    #references-page h2 {
        margin-bottom: 3.75vh;
    }

    #references-page p {
        margin-bottom: 2.5vh;
    }
}

@media only screen and (min-width: 768px) {
    #references-page {
        padding: 7.5vh 15vw 5vh 15vw;
    }

    #references-page h2 {
        margin-bottom: 5vh;
    }

    #references-page p {
        margin-bottom: 2.75vh;
    }
}

@media only screen and (min-width: 992px) {
    #references-page {
        padding: 7.5vh 15vw 5vh 15vw;
    }

    #references-page h2 {
        margin-bottom: 5vh;
    }

    #references-page p {
        margin-bottom: 2.75vh;
    }
}

@media only screen and (min-width: 1200px) {
    #references-page {
        padding: 5vh 7.5vw 0 7.5vw;
    }

    #references-page h2 {
        margin-bottom: 3.75vh;
    }

    #references-page p {
        margin-bottom: 1.5vh;
    }
}
