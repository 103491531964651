#results-page {
    background-color: var(--color-white-medium);
    min-height: 100vh;
    width: 100vw;
}

#results-page-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#results-icons-link,
#results-link-symbol-left,
#results-link-symbol-right {
    fill: var(--color-pink-base);
    stroke: var(--color-pink-base);
    stroke-width: 2;
}

#results-centered-text {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    text-align: center;
}

#results-explanation-text-title {
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
}

#results-explanation-text-date {
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 500;
}

#results-explanation-text-body {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    width: max(25vw, 475px);
}

#results-overview-markers line {
    stroke: var(--color-dark-base);
}

#results-overview-markers text {
    fill: var(--color-purple-medium);
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 600;
}

.day-details-marker .horizontal-line {
    stroke: var(--color-gray-base);
}

.day-details-marker .vertical-line {
    stroke: var(--color-gray-base);
}

.day-details-marker text {
    font-family: 'IBM Plex Mono', sans-serif;
    font-weight: 600;
    font-size: 0.8em;
}

.day-details-rect-day,
.day-details-text-day {
    fill: var(--color-blue-medium);
}

.day-details-rect-week,
.day-details-text-week {
    fill: var(--color-green-medium);
}

.day-details-rect-month,
.day-details-text-month {
    fill: var(--color-purple-medium);
}

#results-svg .birth-day-marker line {
    stroke: var(--color-gray-base);
    stroke-dasharray: 12 6;
}

#results-svg .birth-day-marker text {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
}

#results-exam-disclaimer-wrapper {
    fill: var(--color-lightpink-base);
    -webkit-filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
    filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
}

#results-exam-disclaimer-text {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
    text-align: center;
}

#results-exam-disclaimer-text .weight-700 {
    font-weight: 700;
}

#term-period-marker {
    stroke: var(--color-blue-dark);
}

#term-period-marker-text {
    fill: var(--color-blue-dark);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    text-transform: uppercase;
}

#results-chart-y-axis path,
#results-chart-y-axis .tick line {
    display: none;
}

#results-chart-y-axis-title {
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 500;
    fill: var(--color-dark-base);
    text-transform: uppercase;
}

#results-chart-y-axis text {
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
    fill: var(--color-dark-base);
}

.results-day-bar {
    fill: var(--color-blue-light);
}

.results-week-bar {
    fill: var(--color-green-light);
}

.results-month-bar {
    fill: var(--color-purple-light);
}

.results-day-bar-text {
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
    fill: var(--color-blue-dark);
}

.highlighted-line-day {
    fill: var(--color-blue-dark);
}

.highlighted-large-day {
    fill: var(--color-blue-medium);
}

.highlighted-wide-day {
    fill: var(--color-white-light);
    stroke: var(--color-blue-dark);
}

.highlighted-week {
    fill: var(--color-green-medium);
}

.highlighted-month {
    fill: var(--color-purple-medium);
}

#results-follow-up-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-blue-light);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
}

#results-follow-up-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#results-follow-up-question {
    color: var(--color-purple-dark);
    font-family: 'Readex Pro', sans-serif;
    font-weight: 700;
    text-align: center;
}

#results-page.step-2 #results-icon-link #results-page.step-3 #results-icon-link,
#results-page.step-4 #results-icon-link {
    opacity: 50%;
}

#results-page.step-2-hide-today .results-month-bar,
#results-page.step-2 .results-month-bar,
#results-page.step-3-hide-today .results-week-bar,
#results-page.step-3 .results-week-bar,
#results-page.step-4-hide-today .results-day-bar,
#results-page.step-4 .results-day-bar {
    mix-blend-mode: multiply;
}

#results-page.step-4-hide-today #results-explanation-text-date,
#results-page.step-4 #results-explanation-text-date,
#results-page.step-5-hide-today #results-explanation-text-date,
#results-page.step-5 #results-explanation-text-date {
    display: none;
}

@media only screen and (max-width: 600px) {
    #results-page {
        padding-top: 2.5vh;
    }

    #results-page-content {
        min-height: 90vh;
    }

    #results-svg {
        height: 45vh;
        width: 100vw;
    }

    #results-centered-text,
    #results-explanation-text,
    #results-exam-disclaimer-wrapper,
    #results-exam-disclaimer-text,
    .results-chart-floating-wrapper,
    .results-chart-floating-text,
    .results-chart-arrow {
        display: none;
    }

    #results-overview-markers line {
        stroke-dasharray: 1 0;
    }

    #results-overview-markers text {
        font-size: 1.865em;
    }

    .day-details-marker .horizontal-line {
        display: none;
    }

    .day-details-marker .vertical-line {
        stroke-dasharray: 8 8;
        stroke-width: 1.5;
    }

    .day-details-marker text {
        font-size: 1.865em;
        font-weight: 600;
    }

    #term-period-marker {
        display: none;
    }

    #term-period-marker-text {
        display: none;
        font-size: 1.825em;
        letter-spacing: 0.05em;
    }

    #results-svg .birth-day-marker text {
        font-size: 1.875em;
    }

    #results-page.step-9-hide-today #term-period-marker-text,
    #results-page.step-10 #term-period-marker-text {
        display: unset;
    }

    #results-chart-y-axis-title {
        display: none;
    }

    #results-chart-y-axis text {
        font-size: 2.5em;
    }

    #results-chart-gridlines {
        stroke: var(--color-dark-base);
        stroke-dasharray: 15 15;
    }

    #results-follow-up-wrapper {
        height: 92.5vh;
        margin-top: -2.5vh;
        padding: 2.5vh 7.5vw;
    }

    #results-follow-up-content {
        gap: 7.5vw;
    }

    .results-follow-up-flower,
    .results-follow-up-flower img {
        height: 22.5vw;
        width: 22.5vw;
    }

    #results-follow-up-question {
        font-size: 1.5em;
        line-height: 125%;
    }
}

@media only screen and (min-width: 600px) {
    #results-page {
        padding-top: 2.5vh;
    }

    #results-page-content {
        min-height: 91.5vh;
    }

    #results-svg {
        height: 45vh;
        width: 100vw;
    }

    #results-centered-text,
    #results-explanation-text,
    #results-exam-disclaimer-wrapper,
    #results-exam-disclaimer-text,
    .results-chart-floating-wrapper,
    .results-chart-floating-text,
    .results-chart-arrow {
        display: none;
    }

    #results-overview-markers line {
        stroke-dasharray: 4 4;
    }

    #results-overview-markers text {
        font-size: 1.5em;
    }

    .day-details-marker .horizontal-line {
        display: none;
    }

    .day-details-marker .vertical-line {
        stroke-dasharray: 4 4;
    }

    .day-details-marker text {
        font-size: 1.6em;
        font-weight: 600;
        letter-spacing: 0.015em;
    }

    #term-period-marker {
        display: none;
    }

    #term-period-marker-text {
        display: none;
        font-size: 1.6em;
        letter-spacing: 0.025em;
    }

    #results-svg .birth-day-marker text {
        font-size: 1.6em;
    }

    #results-page.step-9-hide-today #term-period-marker-text,
    #results-page.step-10 #term-period-marker-text {
        display: unset;
    }

    #results-chart-y-axis-title {
        display: none;
    }

    #results-chart-y-axis text {
        font-size: 2.25em;
    }

    #results-chart-gridlines {
        stroke: var(--color-gray-base);
        stroke-dasharray: 10 10;
    }

    #results-follow-up-wrapper {
        height: 94vh;
        margin-top: -2.5vh;
        padding: 2.5vh 15vw;
    }

    #results-follow-up-content {
        gap: 10vw;
    }

    .results-follow-up-flower,
    .results-follow-up-flower img {
        height: 18.5vw;
        width: 18.5vw;
    }

    #results-follow-up-question {
        font-size: 2em;
        line-height: 125%;
    }
}

@media only screen and (min-width: 768px) {
    #results-overview-markers line {
        stroke-dasharray: 6 6;
    }

    .day-details-marker .horizontal-line {
        display: unset;
        stroke-width: 0.5;
    }

    .day-details-marker .vertical-line {
        stroke-dasharray: 6 6;
    }

    .day-details-marker text {
        font-size: 1.3em;
    }

    #term-period-marker {
        display: unset;
    }

    #term-period-marker-text {
        font-size: 1.3em;
    }

    #results-svg .birth-day-marker text {
        font-size: 1.3em;
    }

    #results-chart-y-axis text {
        font-size: 1.75em;
    }

    #results-chart-gridlines {
        stroke-dasharray: 10 10;
    }

    #results-follow-up-wrapper {
        padding: 2.5vh 15vw;
    }

    #results-follow-up-content {
        gap: 7.5vw;
    }

    .results-follow-up-flower,
    .results-follow-up-flower img {
        height: 17.5vw;
        width: 17.5vw;
    }

    #results-follow-up-question {
        font-size: 2.25em;
        line-height: 125%;
    }
}

@media only screen and (min-width: 992px) {
    #results-overview-markers line {
        stroke-dasharray: 4 4;
    }

    #results-overview-markers text {
        font-size: 1em;
    }

    .day-details-marker .vertical-line {
        stroke-dasharray: 4 4;
    }

    .day-details-marker text {
        font-size: 1.05em;
        font-weight: 600;
        letter-spacing: 0.05em;
    }

    #term-period-marker-text {
        display: unset;
        font-size: 1.05em;
        letter-spacing: 0.05em;
    }

    #results-svg .birth-day-marker line {
        stroke-dasharray: 10 5;
    }

    #results-svg .birth-day-marker text {
        font-size: 1.05em;
    }

    #results-chart-y-axis text {
        font-size: 1.05em;
    }

    #results-chart-gridlines {
        stroke-dasharray: 10 10;
    }

    #results-follow-up-wrapper {
        padding: 2.5vh 20vw;
    }

    #results-follow-up-content {
        gap: 5vw;
    }

    .results-follow-up-flower,
    .results-follow-up-flower img {
        height: 17.5vw;
        width: 17.5vw;
    }

    #results-follow-up-question {
        font-size: 3em;
        line-height: 125%;
    }
}

@media only screen and (min-width: 1200px) {
    #results-page {
        padding: 2.5vh 0;
    }

    #results-page-content {
        min-height: 95vh;
    }

    #results-svg {
        height: 82.5vh;
    }

    #results-centered-text,
    #results-explanation-text,
    #results-exam-disclaimer-wrapper,
    #results-exam-disclaimer-text,
    .results-chart-floating-wrapper,
    .results-chart-floating-text,
    .results-chart-arrow {
        display: unset;
    }

    #results-centered-text {
        font-size: 1.5em;
        text-anchor: middle;
    }

    #results-explanation-text-title {
        font-size: 2em;
    }

    #results-explanation-text-date {
        font-size: 0.9em;
    }

    #results-explanation-text-body {
        font-size: 0.95em;
        line-height: 1.5em;
    }

    #results-exam-disclaimer-text {
        text-anchor: middle;
    }

    #results-overview-markers line {
        stroke-dasharray: 2 2;
    }

    #results-overview-markers text {
        font-size: 0.9em;
    }

    .day-details-marker .vertical-line {
        stroke-dasharray: 2 2;
    }

    .day-details-marker text {
        font-size: 0.85em;
    }

    #results-exam-disclaimer-text {
        font-size: 0.7em;
    }

    #term-period-marker-text {
        font-size: 0.75em;
    }

    #results-svg .birth-day-marker line {
        stroke-dasharray: 6 3;
    }

    #results-svg .birth-day-marker text {
        font-size: 0.75em;
    }

    #results-chart-y-axis text {
        font-size: 1.05em;
    }

    .results-chart-floating-wrapper {
        fill: var(--color-white-light);
        -webkit-filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
        filter: drop-shadow(2px 4px 4px rgb(0 0 0 / 0.4));
    }

    .results-chart-floating-text {
        fill: var(--color-dark-base);
        font-family: 'Nunito', sans-serif;
        font-size: 0.875em;
        font-style: italic;
        font-weight: 700;
        text-anchor: middle;
    }

    #results-chart-y-axis-title {
        display: unset;
        font-size: 0.9em;
    }

    #results-chart-y-axis text {
        font-size: 0.8em;
    }

    #results-chart-gridlines {
        stroke-dasharray: 5 5;
    }

    #results-follow-up-wrapper {
        height: 100vh;
        margin-top: -2.5vh;
        padding: 10.5vh 25vw 15.5vh 25vw;
    }

    #results-follow-up-content {
        gap: 4vw;
    }

    .results-follow-up-flower,
    .results-follow-up-flower img {
        height: 7.5vw;
        width: 7.5vw;
    }

    #results-follow-up-question {
        font-size: 2.5em;
        line-height: 125%;
    }
}
