.number-input {
    border: 1px solid var(--color-purple-medium);
    color: var(--color-gray-base);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.number-input-side-text {
    color: var(--color-gray-base);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.number-input-label {
    color: var(--color-dark-base);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.number-input-width-full {
    display: block;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .number-input-inner-wrapper {
        margin-bottom: 0.75vh;
    }

    .number-input {
        font-size: 1.15em;
        padding: 1.25vh 3vw;
        text-align: center;
    }

    .number-input-side-text {
        font-size: 1.15em;
        padding-left: 3vw;
    }

    .number-input-label {
        font-size: 0.85em;
    }
}

@media only screen and (min-width: 600px) {
    .number-input-inner-wrapper {
        margin-bottom: 0.75vh;
    }

    .number-input {
        font-size: 1.4em;
        padding: 1.25vh 2.5vw;
        text-align: center;
    }

    .number-input-side-text {
        font-size: 1.4em;
        padding-left: 2.5vw;
    }

    .number-input-label {
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 768px) {
    .number-input {
        font-size: 1.7em;
    }

    .number-input-side-text {
        font-size: 1.7em;
    }

    .number-input-label {
        font-size: 1.25em;
    }
}

@media only screen and (min-width: 992px) {
    .number-input {
        font-size: 2.25em;
    }

    .number-input-side-text {
        font-size: 2.25em;
    }

    .number-input-label {
        font-size: 1.75em;
    }
}

@media only screen and (min-width: 1200px) {
    .number-input-inner-wrapper {
        margin-bottom: 0.75vh;
    }

    .number-input {
        font-size: 1.25em;
        padding: 1.15vh 0.75vw 1.15vh 1.5vw;
        text-align: left;
    }

    .number-input-side-text {
        font-size: 1.25em;
        padding-left: 0.75vw;
    }

    .number-input-label {
        font-size: 1em;
    }
}
