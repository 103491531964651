#extras-births-by-type-view {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100vw;
}

#births-by-type-chart-title {
    border-bottom: 3px solid var(--color-white-dark);
    color: var(--color-dark-base);
    cursor: help;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    text-align: center;
}

.births-by-type-chart-cptp-bar {
    fill: var(--birth-type-pink);
}

.births-by-type-chart-cip-bar {
    fill: var(--birth-type-orange);
}

.births-by-type-chart-vi-bar {
    fill: var(--birth-type-purple);
}

.births-by-type-chart-vni-bar {
    fill: var(--birth-type-blue);
}

#births-by-type-chart-svg .day-marker-line {
    fill: var(--color-purple-light);
    stroke: var(--color-purple-light);
    stroke-dasharray: 12 6;
}

#births-by-type-chart-svg .day-marker-line:nth-of-type(2) {
    fill: var(--color-dark-base);
    stroke: var(--color-dark-base);
    stroke-dasharray: 12 6;
}

#births-by-type-chart-svg .day-marker-text {
    fill: var(--color-dark-base);
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
}

#births-by-type-chart-svg .births-by-type-chart-circle-cptp,
#births-by-type-chart-svg .births-by-type-chart-circle-cip,
#births-by-type-chart-svg .births-by-type-chart-circle-vi,
#births-by-type-chart-svg .births-by-type-chart-circle-vni {
    fill-opacity: 25%;
    stroke-width: 0.5;
}

#births-by-type-chart-svg .births-by-type-chart-circle-cptp {
    fill: var(--birth-type-pink);
    stroke: var(--birth-type-pink);
}

#births-by-type-chart-svg .births-by-type-chart-circle-cip {
    fill: var(--birth-type-orange);
    stroke: var(--birth-type-orange);
}

#births-by-type-chart-svg .births-by-type-chart-circle-vi {
    fill: var(--birth-type-purple);
    stroke: var(--birth-type-purple);
}

#births-by-type-chart-svg .births-by-type-chart-circle-vni {
    fill: var(--birth-type-blue);
    stroke: var(--birth-type-blue);
}

#births-by-type-chart-svg .births-by-type-chart-text-cptp,
#births-by-type-chart-svg .births-by-type-chart-text-cip,
#births-by-type-chart-svg .births-by-type-chart-text-vi,
#births-by-type-chart-svg .births-by-type-chart-text-vni {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 700;
    line-height: 125%;
}

#births-by-type-chart-svg .births-by-type-chart-text-cptp {
    fill: var(--birth-type-pink);
}

#births-by-type-chart-svg .births-by-type-chart-text-cip {
    fill: var(--birth-type-orange);
}

#births-by-type-chart-svg .births-by-type-chart-text-vi {
    fill: var(--birth-type-purple);
}

#births-by-type-chart-svg .births-by-type-chart-text-vni {
    fill: var(--birth-type-blue);
}

#births-by-type-chart-mb-text {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-light);
    color: var(--color-dark-base);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    overflow: auto;
    width: 100vw;
}

#births-by-type-chart-mb-text > span:nth-child(1) {
    font-family: 'Nunito', sans-serif;
}

#births-by-type-chart-mb-text > span:nth-child(2),
#births-by-type-chart-side-text p:nth-child(2) {
    font-family: 'Readex Pro';
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    #extras-births-by-day-view {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 85vh;
    }

    #births-by-type-chart-title {
        font-size: 0.775em;
        margin: 0 2.5vw;
        height: 6.25vh;
        padding-bottom: 1.5vh;
        text-align: center;
    }

    #births-by-type-chart-side-text {
        display: none;
    }

    #births-by-type-chart-svg {
        height: 49.25vh;
        width: 100vw;
    }

    #births-by-type-chart-svg .day-marker-text {
        font-size: 1.875em;
    }

    #births-by-type-chart-svg .complementary-text {
        display: none;
    }

    #births-by-type-chart-svg .births-by-type-chart-text-cptp,
    #births-by-type-chart-svg .births-by-type-chart-text-cip,
    #births-by-type-chart-svg .births-by-type-chart-text-vi,
    #births-by-type-chart-svg .births-by-type-chart-text-vni {
        font-size: 2em;
    }

    #births-by-type-chart-mb-text {
        height: 33.75vh;
        padding: 2.5vh 7.5vw;
    }

    #births-by-type-chart-mb-text > span:nth-child(1) {
        font-size: 0.9em;
        line-height: 1.4em;
    }

    #births-by-type-chart-mb-text > span:nth-child(2) {
        font-size: 1.25em;
        line-height: 1.25em;
        margin-top: 2vh;
    }
}

@media only screen and (min-width: 600px) {
    #extras-births-by-day-view {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        min-height: 87.5vh;
    }

    #births-by-type-chart-title {
        font-size: 1.1em;
        margin: 0 10vw;
        height: 6.25vh;
        padding-bottom: 1.5vh;
        text-align: center;
    }

    #births-by-type-chart-side-text {
        display: none;
    }

    #births-by-type-chart-svg {
        height: 50vh;
        width: 100vw;
    }

    #births-by-type-chart-svg .day-marker-text {
        font-size: 1.6em;
    }

    #births-by-type-chart-svg .complementary-text {
        display: none;
    }

    #births-by-type-chart-svg .births-by-type-chart-text-cptp,
    #births-by-type-chart-svg .births-by-type-chart-text-cip,
    #births-by-type-chart-svg .births-by-type-chart-text-vi,
    #births-by-type-chart-svg .births-by-type-chart-text-vni {
        font-size: 2em;
    }

    #births-by-type-chart-mb-text {
        height: 35vh;
        padding: 2.5vh 12.5vw;
    }

    #births-by-type-chart-mb-text > span:nth-child(1) {
        font-size: 1.2em;
        line-height: 1.4em;
    }

    #births-by-type-chart-mb-text > span:nth-child(2) {
        font-size: 1.75em;
        line-height: 1.25em;
        margin-top: 2vh;
    }
}

@media only screen and (min-width: 768px) {
    #births-by-type-chart-title {
        font-size: 1.25em;
        margin: 0 12.5vw;
    }

    #births-by-type-chart-mb-text {
        padding: 2.5vh 17.5vw;
    }

    #births-by-type-chart-svg .day-marker-text {
        font-size: 1.3em;
    }

    #births-by-type-chart-svg .births-by-type-chart-text-cptp,
    #births-by-type-chart-svg .births-by-type-chart-text-cip,
    #births-by-type-chart-svg .births-by-type-chart-text-vi,
    #births-by-type-chart-svg .births-by-type-chart-text-vni {
        font-size: 2em;
    }

    #births-by-type-chart-mb-text > span:nth-child(1) {
        font-size: 1.2em;
        line-height: 1.4em;
    }

    #births-by-type-chart-mb-text > span:nth-child(2) {
        font-size: 1.9em;
        line-height: 1.25em;
    }
}

@media only screen and (min-width: 992px) {
    #births-by-type-chart-title {
        font-size: 1.75em;
    }

    #births-by-type-chart-svg .day-marker-line,
    #births-by-type-chart-svg .day-marker-line:nth-of-type(2) {
        stroke-dasharray: 10 5;
    }

    #births-by-type-chart-svg .day-marker-text {
        font-size: 1.05em;
    }

    #births-by-type-chart-svg .births-by-type-chart-text-cptp,
    #births-by-type-chart-svg .births-by-type-chart-text-cip,
    #births-by-type-chart-svg .births-by-type-chart-text-vi,
    #births-by-type-chart-svg .births-by-type-chart-text-vni {
        font-size: 2em;
    }

    #bbd-chart-mb-text {
        padding: 2.5vh 20vw;
    }

    #births-by-type-chart-mb-text > span:nth-child(1) {
        font-size: 1.6em;
        line-height: 1.4em;
    }

    #births-by-type-chart-mb-text > span:nth-child(2) {
        font-size: 2.25em;
        line-height: 1.25em;
    }
}

@media only screen and (min-width: 1200px) {
    #births-by-type-chart-title {
        font-size: 1.25em;
        height: unset;
        padding: 1.5vh;
        width: 60%;
    }

    #births-by-type-chart-svg {
        height: 82.5vh;
        width: 65vw;
    }

    #births-by-type-chart-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    #births-by-type-chart-svg .day-marker-line,
    #births-by-type-chart-svg .day-marker-line:nth-of-type(2) {
        stroke-dasharray: 6 3;
    }

    #births-by-type-chart-svg .day-marker-text {
        font-size: 0.75em;
    }

    #births-by-type-chart-svg .complementary-text {
        display: unset;
    }

    #births-by-type-chart-svg .births-by-type-chart-text-cptp,
    #births-by-type-chart-svg .births-by-type-chart-text-cip,
    #births-by-type-chart-svg .births-by-type-chart-text-vi,
    #births-by-type-chart-svg .births-by-type-chart-text-vni {
        font-size: 1.1em;
        line-height: 115%;
    }

    #births-by-type-chart-side-text {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 7.5vw;
        width: 35vw;
    }

    #births-by-type-chart-side-text p:nth-child(2) {
        font-size: 1.75em;
        line-height: 1.25em;
        margin-top: 3.5vh;
    }

    #births-by-type-chart-mb-text {
        display: none;
    }
}
