#extras-intro-view {
    background-color: var(--color-white-medium);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
}

#extras-intro-image {
    margin: auto 0;
}

#extras-intro-content {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    #extras-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 85vh;
    }

    #extras-intro-image {
        display: none;
    }

    #extras-intro-content {
        width: 85vw;
    }

    #extras-intro-content h3 {
        margin-bottom: 3.5vh;
    }

    #extras-intro-content p {
        margin-bottom: 2.5vh;
    }

    #extras-intro-content p:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 600px) {
    #extras-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 90vh;
    }

    #extras-intro-image {
        display: none;
    }

    #extras-intro-content {
        width: 80vw;
    }

    #extras-intro-content h3 {
        margin-bottom: 3.5vh;
    }

    #extras-intro-content p {
        margin-bottom: 2.5vh;
    }

    #extras-intro-content p:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) {
    #extras-intro-content {
        width: 65vw;
    }
}

@media only screen and (min-width: 992px) {
    #extras-intro-content {
        width: 60vw;
    }
}

@media only screen and (min-width: 1200px) {
    #extras-intro-view {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 5vw;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 85vh;
        padding: 0 5vw;
    }

    #extras-intro-image {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 85vh;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 50vw;
    }

    #extras-intro-image img {
        height: auto;
        max-height: 85vh;
        max-width: 50vw;
        width: auto;
    }

    #extras-intro-content {
        margin-top: 5vh;
    }

    #extras-intro-content h3 {
        width: 30vw;
    }

    #extras-intro-content p {
        width: 25vw;
    }
}
