#home-page {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: var(--color-white-medium);
    background-image: url('../../assets/stripes.png');
    background-position: bottom;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100vw;
}

#home-page-content,
#home-page-buttons {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    #home-page {
        background-size: 90% 2.5%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 2.5vh 0;
    }

    #home-page-top-flower,
    #home-page-left-flower,
    #home-page-right-flower {
        display: none;
    }

    #home-page-logo {
        margin-bottom: 6.25vh;
        min-height: 33.45vw;
        width: 85vw;
    }

    #home-page-logo img {
        height: auto;
        width: 85vw;
    }

    #home-page-text {
        width: 80vw;
    }

    #home-page-text p {
        margin-bottom: 3.125vh;
    }

    #home-page-buttons {
        margin-top: 3.125vh;
        width: 80vw;
    }

    #home-page-buttons button:first-child {
        margin-bottom: 1.25vh;
        width: 100%;
    }

    #home-page-buttons button:nth-child(2) {
        color: var(--color-blue-dark);
        font-size: 0.75em;
    }
}

@media only screen and (min-width: 600px) {
    #home-page {
        background-size: 90% 2.5%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 2.5vh 0;
    }

    #home-page-top-flower,
    #home-page-left-flower,
    #home-page-right-flower {
        display: none;
    }

    #home-page-logo {
        margin-bottom: 6.25vh;
        min-height: 31.48vw;
        width: 80vw;
    }

    #home-page-logo img {
        height: auto;
        width: 80vw;
    }

    #home-page-text {
        width: 65vw;
    }

    #home-page-text p {
        margin-bottom: 3.125vh;
    }

    #home-page-buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-top: 3.125vh;
        width: 70vw;
    }

    #home-page-buttons button:first-child {
        margin-bottom: 1.25vh;
        width: 100%;
    }

    #home-page-buttons button:nth-child(2) {
        color: var(--color-blue-dark);
        font-size: 1em;
    }
}

@media only screen and (min-width: 768px) {
    #home-page {
        padding: 5% 0;
    }

    #home-page-buttons {
        margin-top: 3.125vh;
        width: 65vw;
    }

    #home-page-buttons button:first-child {
        margin-bottom: 2.75vh;
        width: 100%;
    }

    #home-page-buttons button:nth-child(2) {
        font-size: 1.25em;
    }
}

@media only screen and (min-width: 992px) {
    #home-page {
        background-size: 80% 2.5%;
    }

    #home-page-logo {
        min-height: 25.58vw;
        width: 65vw;
    }

    #home-page-logo img {
        width: 65vw;
    }

    #home-page-text {
        width: 60vw;
    }

    #home-page-buttons {
        width: 60vw;
    }

    #home-page-buttons button:nth-child(2) {
        font-size: 1.75em;
    }
}

@media only screen and (min-width: 1200px) {
    #home-page {
        background-size: 75% 2.5%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        gap: 2.5vw;
        max-width: 100vw;
        padding: 0 0 2.5% 0;
    }

    #home-page-content {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        height: 95vh;
    }

    #home-page-left-flower {
        display: unset;
        height: calc(11.25em + 15vh);
        padding-bottom: 15vh;
        width: 11.25em;
    }

    #home-page-right-flower {
        display: unset;
        height: calc(11.25em + 30vh);
        padding-top: 30vh;
        width: 11.25em;
    }

    #home-page-left-flower img,
    #home-page-right-flower img {
        height: auto;
        width: 11.25em;
    }

    #home-page-top-flower {
        display: unset;
        margin-bottom: 2.5vh;
    }

    #home-page-top-flower img {
        height: auto;
        width: 11.25em;
    }

    #home-page-logo {
        min-height: 15.75vw;
        width: 40vw;
    }

    #home-page-logo img {
        width: 40vw;
    }

    #home-page-text {
        width: 32.5vw;
    }

    #home-page-buttons {
        width: 50vw;
    }

    #home-page-buttons button:first-child {
        margin-bottom: 0.25em;
        min-width: 25em;
        width: 50%;
    }

    #home-page-buttons button:nth-child(2) {
        font-size: 1em;
    }
}
