.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.decoration-italic {
    font-style: italic;
}

.weight-500 {
    font-weight: 500;
}

.weight-600 {
    font-weight: 600;
}

.weight-700 {
    font-weight: 700;
}

.weight-800 {
    font-weight: 800;
}

.weight-900 {
    font-weight: 900;
}
