.date-input {
    border: 1px solid var(--color-purple-medium);
    color: var(--color-gray-base);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.date-input-label {
    color: var(--color-dark-base);
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
}

.date-input-width-full {
    display: block;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .date-input {
        font-size: 1.15em;
        margin-bottom: 0.75vh;
        padding: 1.25vh 5vw;
    }

    .date-input-label {
        font-size: 0.85em;
    }
}

@media only screen and (min-width: 600px) {
    .date-input {
        font-size: 1.4em;
        margin-bottom: 0.75vh;
        padding: 1.25vh 5vw;
    }

    .date-input-label {
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 768px) {
    .date-input {
        font-size: 1.7em;
    }

    .date-input-label {
        font-size: 1.25em;
    }
}

@media only screen and (min-width: 992px) {
    .date-input {
        font-size: 2.25em;
    }

    .date-input-label {
        font-size: 1.75em;
    }
}

@media only screen and (min-width: 1200px) {
    .date-input {
        font-size: 1.25em;
        margin-bottom: 0.75vh;
        padding: 1vh 1.5vw;
    }

    .date-input-label {
        font-size: 1em;
    }
}
